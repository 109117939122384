import queryString from 'query-string';
import { axiosClient } from '../axiosClient';
import { TGetUserInfo } from './types/userInfoTypes';

const userInfoApi = {
  /*
    Get user info of current user
  */
  getCurrentUser: () => {
    const url = '/api/userinfo/self';
    return axiosClient.get(url);
  },
  /*
    Get all user list
  */

  getOptimizedUserInfo: (params: TGetUserInfo) => {
    const url = '/api/userinfo';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },

  getUserInfo: (params: TGetUserInfo) => {
    const url = '/api/userinfo';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },

  getUserInfoById: (id: string) => {
    const url = '/api/userinfo';
    return axiosClient.get(`${url}/${id}`);
  },

  getExpertCareerMasterDetails: () => {
    const url = '/api/expert-career-master';
    return axiosClient.get(`${url}`);
  },
  /*
    Create user resume
  */
  add: (data: FormData) => {
    const url = '/api/userinfo';
    return axiosClient.post<FormData>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  /*
    Update user resume
  */
  update: (data: FormData) => {
    const url = '/api/userinfo/self';
    return axiosClient.patch<FormData>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  updateWithJSON: (data: any) => {
    const url = '/api/userinfo/self';
    return axiosClient.patch(url, data);
  },

  /*
    Update user info User
  */
  updateUserSelf: (data: FormData) => {
    const url = '/api/user/self';
    return axiosClient.patch<FormData>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  updateUserExpert: (id: string, data: FormData) => {
    const url = `/api/user/${id}`;
    return axiosClient.patch<FormData>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  updateInfoExpert: (id: string, data: FormData) => {
    const url = `/api/userinfo/${id}`;
    return axiosClient.patch<FormData>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  expertContractAccept: (id: string, data: any) => {
    const url = `/api/userinfo/contract/${id}`;
    return axiosClient.patch<FormData>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export default userInfoApi;
