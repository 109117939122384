import { Assign } from './assign';

// XC = xcare (admin)
// CL = client
// EX = expert
export type UserRole = 'XC' | 'CL' | 'EX' | '*';

export const Roles = {
  Xcare: 'XC',
  Expert: 'EX',
  Client: 'CL',
};

export const PrimaryContactRoles = {
  Facebook: 'FB',
  LinkedIn: 'LI',
  Others: 'OT',
  None: 'NO',
};

export type UserInfoMinimal = {
  user: number;
  avatar_image: string | null;
  role: UserRole;
  user_other_info: {
    first_name: string;
    last_name: string;
    email: string;
  };
};

export type Review = {
  id?: number;
  expert_career?: any;
  expert_career_objects?: any;
  modalities?: any;
  categories?: any;
  xcare_meeting?: string | null;
  xcare_person?: number;
  registration_status_cd?: number;
  registration_status_text?: string;
  nda_contract?: boolean;
  basic_contract?: boolean;
  appearance?: boolean;
  show_face?: boolean;
  average_score?: any;
  note?: string;
  expert?: number;
  is_pm?: boolean;
  skill?: number[];
  listening_skill?: number | null;
  understanding_needs?: number | null;
  proposal_skill?: number | null;
  self_management?: number | null;
  creative?: number | null;
  excel_ppt_reporting?: number | null;
  it_literacy?: number | null;
  collaborative_and_leadership?: number | null;
  weekday_day?: boolean;
  weekday_nights?: boolean;
  available_only_holiday?: boolean;
  work_weekday_day?: boolean;
  weekday_other?: boolean;
  professional_evaluation?: ProfessionalEvaluationT;
  english_skill_detail?: EnglishSkillDetailT;
  reading?: any;
  writing?: any;
  speaking?: any;
  listening?: any;
  overall?: any;
  fee?: any;
  characteristics?: string;
  evaluation_for_deliverables?: string;
  light_project_capable?: any;
  deep_project_capable?: any;
  consumption_taxable?: any;
  desired_notice_period?: any;
  annual_income?: any;
  job_change_reason?: any;
  bill_issuing_business?: any;
  share_resume?: any;
  bill_issuing_business_number?: any;
  desired_annual_income?: any;
  share_resume_obj?: any;
  desired_job?: any;
  business_form?: any;
  seeking_job?: any;
  seeking_job_text?: any;
  annual_income_text?: any;
  desired_annual_income_text?: any;
  desired_notice_period_text?: any;
  business_form_text?: any;
  working_method_text?: any;
  desired_workplaces_objs?: any;
  intension?: any;
  character?: any;
  data_input_date?: any;
  recruitment_agency_obj?: any;
};

export type EnglishSkillDetailT = {
  reading: any;
  writing: any;
  speaking: any;
  listening: any;
  overall?: any;
};

export type ProfessionalEvaluationT = {
  first_time: EvaluationT;
  second_time: EvaluationT;
  third_time: EvaluationT;
};

export type EvaluationT = {
  date: string;
  expert_id: number;
  skill: EvaluationSkillT;
};

export type EvaluationSkillT = {
  creative: number;
  proposal: number;
  listening: number;
  it_literacy: number;
  self_management: number;
  excel_ppt_report: number;
  collab_leadership: number;
  understanding_needs: number;
};

export type UserInfo = {
  type?: string;
  id?: string;
  langKey?: string;
  attributes?: {
    expert_career_objects?: any;
    consumption_taxable?: any;
    desired_notice_period?: any;
    annual_income?: any;
    job_change_reason?: any;
    bill_issuing_business?: any;
    share_resume?: any;
    bill_issuing_business_number?: any;
    light_project_capable?: any;
    deep_project_capable?: any;
    desired_annual_income?: any;
    share_resume_obj?: any;
    desired_job?: any;
    seeking_job?: any;
    seeking_job_text?: any;
    annual_income_text?: any;
    desired_annual_income_text?: any;
    desired_notice_period_text?: any;
    business_form_text?: any;
    working_method_text?: any;
    desired_workplaces_objs?: any;
    reading?: any;
    writing?: any;
    speaking?: any;
    listening?: any;
    desired_task: any;
    working_method: any;
    business_form: any;
    expert_career: any;
    is_info_fulfilled: boolean;
    current_step: any;
    age?: string;
    address?: string;
    user?: number;
    role?: UserRole;
    show_id?: string;
    reviews?: Array<Review>;
    assigns?: Array<Assign>;
    skill_detail?: Array<{
      id?: number;
      name?: string;
    }>;
    nationality_detail?: {
      id?: number;
      name?: string;
      japanese: string;
    };
    user_other_info?: {
      first_name?: string;
      last_name?: string;
      email?: string;
    };
    place_of_residence?: string;
    name_furigana?: string;
    priority_contact_status?: number;
    birthday?: string | null;
    professional?: string;
    genre_medicine?: boolean;
    genre_instruments?: boolean;
    genre_regenerative?: boolean;
    genre_diagnostics?: boolean;
    genre_other?: boolean;
    genre_other_name?: string;
    available_hours?: number;
    available_only_holiday?: boolean;
    weekday_day?: boolean;
    weekday_nights?: boolean;
    weekday_other?: boolean;
    weekday_memo?: string;
    contact_weekday?: boolean;
    work_weekday_day?: boolean;
    other_skill?: string;
    avatar_image?: string;
    message?: string;
    phone?: string;
    other_email?: string;
    facebook?: string;
    linkedin?: string;
    other_social_media?: string;
    primary_contact?: 'FB' | 'LI' | 'OT';
    file_attachment?: Array<{
      id?: number;
      file?: string;
    }>;
    created_at?: string;
    updated_at?: string;
    logged_in?: boolean;
    contracts?: any;
  };
  relationships?: {
    nationality?: {
      data?: {
        type?: string;
        id?: string;
      };
    };
    skill?: {
      data?: Array<{
        type: string;
        id: string;
      }>;
      meta?: {
        count?: number;
      };
    };
  };
};

export type UserInfoData = Exclude<UserInfo['attributes'], undefined>;

export type UserSelf = {
  first_name?: string;
  last_name?: string;
  email?: string;
};

export type TUserInfoQuery = {
  data: Array<UserInfo>;
  links: {
    total: number;
    total_pages: number;
    page_size: number;
    current_page: string;
    total_expert: number;
    total_expert_with_project: number;
  };
};
