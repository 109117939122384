import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { TGetUserInfo } from 'app/axios/api/types/userInfoTypes';
import userInfoApi from 'app/axios/api/userInfoApi';
import { getSkillList } from 'app/pages/ResumePage/slice/resumePageSlice';
import { Roles, UserRole } from 'types/userInfo';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ExpertListPageState } from './expertListPageTypes';

const initialState: ExpertListPageState = {
  userList: [],
  optimizedUserList: [],
  skillList: [],
  loading: false,
};

export const getUserList = async (params: TGetUserInfo) => {
  const data = await userInfoApi.getUserInfo(params);
  return data.data;
};

export const getOptimizedUserList = async (params: TGetUserInfo) => {
  const data = await userInfoApi.getOptimizedUserInfo(params);
  return data.data;
};

export const getOptimizedExpertList = createAsyncThunk(
  'expertListPage/getOptimizedExpertList',
  async (params: TGetUserInfo, thunkAPI) => {
    return getOptimizedUserList({ ...params });
  },
);

export const getExpertList = createAsyncThunk(
  'expertListPage/getExpertList',
  async (params: TGetUserInfo, thunkAPI) => {
    return getUserList({ ...params });
  },
);

export const loadData = createAsyncThunk(
  'expertListPage/loadData',
  async (params: TGetUserInfo, { dispatch }) => {
    dispatch(getExpertList(params));
    dispatch(getOptimizedExpertList(params));
    return await Promise.all([
      // getSkillList()
    ]);
  },
);

const slice = createSlice({
  name: 'expertListPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadData.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(loadData.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
    });
    builder.addCase(loadData.fulfilled, (state, action: PayloadAction<any>) => {
      state.skillList = action?.payload?.[0]?.data;
      state.loading = false;
    });
    builder.addCase(getExpertList.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(getExpertList.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.userList = [];
    });
    builder.addCase(getExpertList.fulfilled, (state, action: PayloadAction<any>) => {
      state.userList = action.payload.data;
      state.userInfoQuery = action.payload;
      state.loading = false;
    });
    builder.addCase(getOptimizedExpertList.pending, (state, action: PayloadAction<any>) => {
      state.loading = true;
    });
    builder.addCase(getOptimizedExpertList.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.optimizedUserList = [];
    });
    builder.addCase(getOptimizedExpertList.fulfilled, (state, action: PayloadAction<any>) => {
      state.optimizedUserList = action.payload.data;
      state.userInfoQuery = action.payload;
      state.loading = false;
    });
  },
});

export const { actions, reducer: expertListPageReducer } = slice;
export default expertListPageReducer;
